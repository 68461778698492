.blogCard-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    width: 100%;
    max-width: 1200px; /* Adjust as needed */
    padding: 2rem;
  }
  
  .card {
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .card:hover img {
    transform: scale(1.1);
  }
  
  .card-image img {
    width: 100%;
    transition: transform 0.3s ease;
    object-fit: cover;
    height: 200px; /* Adjust as needed */
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 16px;
    color: #666;
  }
  
  .tags {
    margin-top: 10px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  
  .tag {
    display: inline-block;
    margin-right: 5px;
    margin-top: 8px;
    padding-bottom: 2px;
    color: #2356bd; /* Set tag text color to blue */
    border-bottom: 2px solid transparent; /* Add transparent bottom border */
    transition: border-bottom-color 0.5s ease; /* Adjust duration for smooth transition */
  }
  
  .tag:hover {
    border-bottom-color: #2356bd; /* Change border color on hover */
  }
  .author-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .author-info {
    display: flex;
    flex-direction: column;
  }
  
  .author-name {
    font-size: 16px;
    font-weight: bold;
  }
  
  .date-posted {
    font-size: 14px;
    color: #666;
  }

  .read-more{
    color: var(--primary);
    letter-spacing: -.05em;
    align-items: center;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5em;
    text-decoration: none;
    color: #2356bd;
    transition: color 0.3s ease;
  }
  .read-more:hover{
    color: black;
  }