/* TestimonialsBanner.css */

.testimonials-banner {
    position: relative;
        color: #fff; 
    text-align: left; 
    padding: 100px 20px; 
  }

  .banner-content {
    max-width: 650px;
    padding: 40px;
    margin-right: auto;

  }
  @media (min-width: 768px) {
    .banner-content {
      margin-right: 20px;
    }
 
  }


  
  .banner-content h2 {
    font-size: 34px; 
  color:white;
  margin-bottom: 10px;
  }
  
  .banner-content p {
    font-size: 24px;
    font-family: Inter, sans-serif;
    color: #ffffff;
    width: 100%;
    line-height: 1.7;
    word-spacing: 4px; 

  }
  
  .clients-container {
    margin-top: 50px;
  }
  
  .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    background-size: 100rem 11rem;
    animation: waves 12s linear infinite;
    background-repeat: repeat-x;
  }
  
  .wave1 {
    z-index: 1; 
    opacity: 0.2; 
    animation-delay: 0s; 
  }
  
  .wave2 {
    z-index: 2; 
    opacity: 0.2; 
    animation-delay: 2s; 
    animation-direction: reverse;
  }
  
  .wave3 {
    z-index: 3; 
    opacity: 0.2;     animation-delay: 4s; 
  }
  
  @keyframes waves {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: 100rem; 
    }
  }
  