/* userReviews.css */

/* Common styles */
.user-reviews-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
}

.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically */
    align-items: center; /* Center the content horizontally */
    width: 40%; /* Decrease the width for .content-container */
    margin-right: 20px;
    text-align: center; /* Align the text to center */
    padding-left: 20px;
    box-sizing: border-box;
}

.content-header {
    text-align: left;
    margin-top: 150px;
    margin-bottom: 80px; /* Add margin to the bottom of the content header */
}

.content-header h2 {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 10px;
    font-family: Inter, sans-serif;
}

.content-header p {
    font-family: Inter, sans-serif;
    letter-spacing: 0;
    font-size: 18px;
    line-height: 34px;
    font-weight: 400;
}

.backQuote p {
    padding: 0%;
    font-family: Inter, sans-serif;
    letter-spacing: 0;
    font-size: 18px;
    line-height: 34px;
    font-weight: 400;
}

.content-paragraph {
    width: 80%;
    margin-left: auto;
    margin-right: 0;
}

.content-header button {
    background-color: #2356bd;
    color: white;
    font-family: Inter, sans-serif;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.content-header button:hover {
    background-color: #0056b3;
}

.testimonial-container {
    width: 60%; /* Adjust width as needed */
  }

  .slide {
    width: 100%; /* Adjust width as needed */
  }
  .testimonial-card {
    height: 600px; 
    background-color: #ffffff; /* Background color for the testimonial card */
    border-radius: 8px; /* Border radius for the testimonial card */
    border: 1px solid #e1e1e1; /* Border color and width for the testimonial card */
    padding: 20px; /* Padding for the testimonial card */
    margin-right: 20px;

}

  .testimonial-profile {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Align items vertically */
    padding-top: 15px; /* Add padding to the top */
  }

.testimonial-img {
  margin-right: 15px; /* Add margin to the right of the image */
}
.testimonial-img img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  
}

.testimonial-details {
    flex-grow: 1; /* Allow the details to grow to fill remaining space */
    margin-left: 10px;
}

.testimonial-details h3 {
    font-size: 18px;
    display: block;
    line-height: 22px;
    margin: 0;
    font-weight: bold;
    padding: 0;
    border: 0;
}

.testimonial-details h4 {
    font-size: 14px;
    color: inherit;
    opacity: .6;
    display: block;
    margin-block-start: 2px;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.testimonial-details p {
    font-size: 16px;
    margin-bottom: 0;
}

/* Responsive styles */

@media only screen and (max-width: 992px) {
    .user-reviews-container {
        flex-direction: column;
        align-items: center;
    }
    .content-container,
    .testimonial-container {
        width: 90%; /* Full width for smaller screens */
        margin-right: 0;
        margin-top: 0px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .user-reviews-container {
        flex-direction: column;
        align-items: center;
    }
    .content-header {
        margin-top: 50px;
        margin-bottom: 80px;
    }
    .content-container,
    .testimonial-container {
        width: 90%; /* Full width for smaller screens */
        margin-right: 0;
        margin-bottom: 20px;
    }
}
