.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f6ff; /* Light blue background */
  }
  
  .content {
    text-align: center;
  }
  
  .title {
    font-size: 48px;
    color: #0056b3; /* Dark blue title color */
  }
  
  .description {
    font-size: 20px;
    color: #333; /* Dark text color */
    margin-bottom: 20px;
  }
  
  .link {
    display: inline-block;
    background-color: #0056b3; /* Dark blue button background */
    color: #fff; /* White button text color */
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .link:hover {
    background-color: #003c7a; /* Darker blue on hover */
  }
  