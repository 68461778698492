.welcome-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh; 
  margin: 30px;
  padding: 0 20px; /* Add padding to the sides */
  min-width: 300px; /* Set a minimum width for the container */
}

.logo-and-message {
  display: flex;
  align-items: center;
  padding: 20px;
  min-width: 300px; 
}


.welcome-content {
  max-width: 1100px; /* Adjust max width as needed */
  width: 100%;
}

.logo-and-message {
  display: flex;
  align-items: center;
}

.logo-container {

  margin-right: 30px;
  text-align: center; 
  width: 160px; 
  height: 160px;
  display: flex; 
  align-items: center;
  justify-content: center; /* Center the logo horizontally */
  border-radius: 50%; /* Make it round */
}

.logo {
  max-width: 100%;
  height: 90px;
}

.text-container {
  flex: 1;
}

.welcome-heading {
  font-size: 2.7em;
  font-weight: 600;
  font-family: Inter, sans-serif;
  color: #171616;
  margin-bottom: 20px;
}

.welcome-description {
  font-size: 1.5em;
  font-family: Inter, sans-serif;
  color: #555;
  max-width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 0px;
}

.button-wrapper {
  margin: 0 10px; 
}

.view-case-study-button {
  background-color: transparent;
  color: #007bff;
  border: 2px solid #007bff;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.read-testimonials-button {
  background-color: #007bff;
  color: #ffffff;
  font-weight: 500;
  border: 2px solid #007bff;
  padding: 8px 16px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.view-case-study-button:hover {
  background-color: #84005d;
  color: white;
}

@media screen and (max-width: 1200px){
  .logo-and-message {


    padding: 20px;
  }
}

@media screen and (max-width: 992px){
  .welcome-container {
    height: auto; 
    padding: 0%;
  }

  .logo-and-message {
    flex-direction: column; 
    align-items: center; 
    margin-bottom: 20px; 

  }

  .logo-container {
    margin-right: 0;
  }

  .logo {
    height: auto; 
    max-height: 100px; 
  }

  .view-case-study-button,
  .read-testimonials-button {
    padding: 6px 12px; 
    font-size: 16px; 
  }
  
  .welcome-description {
    text-align: left;
  }
}
  



@media screen and (max-width: 768px) {
  .welcome-container {
    height: auto; 
    padding: 0%;
  }

  .logo-and-message {
    flex-direction: column; 
    align-items: center; 
    margin-bottom: 20px; 

  }

  .logo-container {
    margin-right: 0;
  }

  .logo {
    height: auto; 
    max-height: 100px; 
  }

  .view-case-study-button,
  .read-testimonials-button {
    padding: 6px 12px; 
    font-size: 16px; 
  }
  
  .welcome-description {
    text-align: left; /* Align paragraph text to the left on smaller screens */
  }
}

@media screen and (max-width: 597px){
  .welcome-container {
    height: auto; 
    padding: 0%;
  }
  .text-container{
    padding: 0%;
  }

}
