.web-development-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  
  .web-development-container {
    padding: 20px;
    background-color: transparent;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;

  }
  
  .web-development-container h2 {
    margin: 20px 0;
    color: rgb(80, 75, 75);
    font-size: 20px;
    text-align: start;
  }
  
  .web-development-container p,
  .cms-Text-container p,
  .crm-Text-container p,
  .customization-Text-container p {
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    color: #666;
    text-align: start;
    line-height: 22px;
    margin-bottom: 20px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .web-development-container h2,
.cms-Text-container h2,
.crm-Text-container h2,
.customization-Text-container h2 {
  color: rgb(80, 75, 75);
  font-size: 20px;
  text-align: start;
  margin: 20px 0;
}
.cms-Text-container h2,
.crm-Text-container h2,
.customization-Text-container h2 {
  margin-bottom: 30px;
}

  .CMS-container {
    display: flex;
    justify-content: center;
    background-color: #efefef;
    width: 100%;
  }
  
  .cms-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
  }
  
  .cms-Text-container {
    flex: 3;
    padding: 20px;
    text-align: center;
  }
  
 
  .cms-Image-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .cms-Image-container img {
    max-width: 100%;
    height: auto;
    max-height: 200px;
 
  }
  


  .CRM-container {
    display: flex;
    justify-content: center;
    background-color: transparent;
    width: 1200px;
    margin-top: 40px; /* Adjust margin as needed */
  }
  
  .crm-Text-container {
    flex: 3;
    padding: 20px;
  }
  

  
  .crm-Text-container ul {
    list-style-type: disc;
    padding-left: 5;
    font-size: 12px;
  }
  
  .crm-Text-container ul li {
    margin-bottom: 5px;
  }
  
  .crm-Image-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .crm-Image-container img {
    max-width: 100%;
    height: auto;
    max-height: 200px;
  }

  
  .Customization-container {
    display: flex;
    justify-content: center;
    background-color: #efefef;
    width: 100%;
    margin-top: 40px; /* Adjust margin as needed */
  }
  
  .customization-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px; /* Adjust max-width as needed */
  }
  
  .customization-Text-container {
    flex: 3;
    padding: 20px;
  }

  

  .customization-Text-container ul {
    list-style-type: disc; /* Use disc bullets */
    padding-left: 20px; /* Add left padding */
  }
  
  .customization-Text-container ul li {
    margin-bottom: 5px;
  }
  
  .customization-Image-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .customization-Image-container img {
    max-width: 100%;
    height: auto;
    max-height: 200px;
  }

  @media screen and (max-width: 992px) {

    .CRM-container {
      width: 100%; /* Make the container occupy full width */
      padding: 8px; /* Adjust padding for better spacing */
    }

  }
  
  @media screen and (max-width: 768px) {
    /* Adjust padding for better spacing */
    .web-development-container,
    .CMS-container,
    .CRM-container,
    .Customization-container,
    .cms-content,
    .crm-content,
    .customization-content {
      padding: 10px;
    }
  
    /* Stack content in a column and center align */
    .web-development-container,
    .CMS-container,
    .CRM-container,
    .Customization-container,
    .cms-content,
    .crm-content,
    .customization-content,
    .cms-Text-container,
    .crm-Text-container,
    .customization-Text-container {
      flex-direction: column;
      align-items: center;

    }
  
    /* Adjust width for better fit */
    .cms-Image-container,
    .crm-Image-container,
    .customization-Image-container,
    .crm-Image-container {
      width: 100%;
      margin-bottom: 20px; /* Add space between image and text */
    }
    
    /* Adjust width for better fit */
    .CRM-container {
      width: 100%; /* Make the container occupy full width */
      padding: 8px; /* Adjust padding for better spacing */
    }
    .crm-Text-container ul {
      list-style-type: disc;
      padding-left: 20px;
      text-align: start; /* Align list items to the start */
    }
  }
  
  