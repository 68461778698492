.request-quote-container {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin: auto;
 background-color: #fcfcfc;
}

.form-container {
  width: 100%;
  margin-bottom: 10px;
}

.contact-info-container {
  width: 100%;
  margin-top: auto;
}

@media (min-width: 768px) {
  .request-quote-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .form-container {
    width: calc(70% - 5px);
    margin-right: 5px;
    margin-bottom: 0;
  }

  .contact-info-container {
    width: calc(100% - 5px);
    margin-top: 0;
  }
}

@media (max-width: 992px) {
  .form-container {
    width: 100%;
    margin-right: 0;
    padding: 30px 35px
  }
  .request-quote-container {
   max-width: 100%;
    display: flex;
    flex-direction: column;
  padding: 10px;

  }

  .contact-info-container {
    max-width: 100%;

    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .request-quote-container {
    max-width: 100%;
    padding: 0%;
  }

  .form-container {
    padding: 0 15px;
  }

  .contact-info-container {
    padding: 0 15px;
  }
}

@media (max-width: 576px) {
  .form-container,
  .contact-info-container {
    padding: 0 10px;
  }
  .contact-info-container {
  
  }
}
