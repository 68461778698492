.container-main {
  display: flex;
  flex-direction: column;
box-shadow: none;
}

.heading-container {
  text-align: center;
  z-index: 1;
  padding: 10px;
  transform: translate3d(0px,0px,0px);
}

.heading-main {
  font-size: 28px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
    font-size: 30px;
  margin: 40px;
  line-height: 1.4;
}

.photo-container {
  flex: 1;
  margin-bottom: 20px;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
