.site-header {
  background-color: #fff;
  width: 100%;
  position: relative;
  z-index: 1000;
}

.top-header-container {
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #f2f2f2;
  margin-right: 15px;
}

.navigation-container {

  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  z-index: inherit;
  margin: 80px auto 0; /* Adjust top margin to accommodate the fixed top header */
  background: #fff!important;
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

}

.header-fab-icon {
  font-size: 0.8em;
  display: block;
  width: 34px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-left: 1px solid #f2f2f2;
}

.social-links {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #666;
}

.site-header,
 .facebook-link,
 .twitter-link,
 .instagram-link,
 .linkedin-link,
 .telegram-link{
  margin-right: 10px; 
}


.site-header .social-links .facebook-link,
.twitter-link,
.instagram-link,
.linkedin-link,
.whatsapp-link,
.telegram-link
 {
  display: inline-block;

  transition: background-color 0.3s ease;
}

/* Facebook */
.site-header .social-links .facebook-link:nth-child(1):hover {
  background-color: #4664a7;
  color: white;
}

/* Twitter */
.site-header .social-links .twitter-link:nth-child(2):hover {
  background-color: #1da1f2;
  color: white;
}

/* Instagram */
.site-header .social-links .instagram-link:nth-child(3):hover {
  background-color: #d72d4c;
  color: white;
}

/* LinkedIn */
.site-header .social-links .linkedin-link:nth-child(4):hover {
  background-color: #0077b5;
  color: white;
}


.site-header .social-links .telegram-link:nth-child(5):hover {
  background-color: #0088cc; /* Change color as desired */
  color: white; /* Change color as desired */
}

.top-header .contact-info {
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: #666;
  overflow-x: hidden;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.top-header .contact-info span {
  display: inline-block;
  font-size: 12px;
  line-height: 0px;
  padding: 6px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  cursor: pointer;
  border-left: 2px solid #666;
  color: #666;
  box-sizing: border-box;
}

.site-header .contact-info span:hover {
  color: #2259c8;
}

.social-and-quote {
  display: flex;
  align-items: center;
}

.social-links {
  display: flex;
  padding: 10px;
}

.social-links .facebook-link,
.twitter-link,
.instagram-link,
.linkedin-link,
.whatsapp-link  {
  margin-right: 0.5rem;
  color: #000;
}

.quote-button {
  background-color: #2356bd;
  color: #ffffff;
  border: none;
  font-family: Inter, sans-serif;
  font-size: 16px;
  padding: 0.3rem 1rem;
  line-height: 33px!important;
  cursor: pointer;
}

.Header-navigations {
  background: #fff!important;
  margin-bottom: 0;
  border: none;
  position: relative;
  min-height: 20px;
  box-sizing: border-box;
  display: block;
}



.companyLogo {
  width: 180px; 
  height: auto; 

  padding: 0;
}

.site-logo img {
  display: block; 
  width: calc(100% - 10px);
  height: auto;
  padding: 8px; 
  
  max-width: 100%; 
  max-height: 100%; 
}
.navigation-links {

  margin-bottom: 0;
  list-style: none;
  transform: none;

}
/* Dropdown Button */
.dropbtn {
  font-size: 14px; /* Reduced font size */
  border: none;
  outline: none;
  color: black;
  background-color: inherit;
  cursor: pointer;
  margin-right: 10px;
}

/* Dropdown Content (Hidden by Default) */

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px; /* Keep the width */
  z-index: 1;
  border: 1px solid #ccc; /* Added border */
  font-family: 'Open Sans', sans-serif;
  font-size: 12px; /* Reduced font size */
  font-weight: 300;
  color: #666;
}

/* Links inside the dropdown */
.site-header .navbar ul li .dropdown-content a{
  display: block;
  color: #1d1c1c;
  font-size: 12px; /* Reduced font size */
  font-family: Inter, sans-serif;
  padding: 12px 6px; /* Adjusted padding to decrease height */
  text-decoration: none;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;

}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  color: #184cb5;
  border: none;
  
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}



.site-header .navbar ul {
  margin-left: 0;
  position: relative;
  display: block;
  margin-left: 6px;
  float: left;
  position: relative;
  display: block;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.site-header .navbar ul li {
  margin: 0 1.5rem;
}

.site-header .navbar ul li a {
  color: #4a4747;
  display: block;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding: 28px 15px 28px 15px;
  overflow: hidden;
  font-weight: 400;
  text-shadow: 0 1px 0 rgba(255,255,255,.25);
  line-height: 20px;
  box-sizing: border-box;
  list-style: none;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-bottom-color 0.3s ease; 
}

.site-header .navbar ul li a:hover,
.site-header .navbar ul li a.active {
  color: #184cb5;
  border-bottom-color: #184cb5; /* Change border color when hovered */
}

.navigation-links ul li a.active,
.navigation-links ul li a:hover,
.dropdown.active .dropbtn {
  color: #184cb5;
  border-bottom-color: #184cb5;
}


.site-header .fa-bars {
  color: #000000;
  cursor: pointer;
  font-size: 3rem;
  display: none;
}
/* Media Query for Small Screens */
@media screen and (max-width: 1200px) {

  .top-header-container {

    padding-right: 100px;
    padding-left: 100px;
  

  }
}
@media screen and (max-width: 768px) {
  .site-header {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0%;
  }
  .top-header-container {
    padding: 0%;
  }
  .top-header {
    width: 100%;
    justify-content: space-between;
  }

  .contact-info {
    display: none; 
  }

  .top-header .contact-info {
    display: block;
    margin-top: 1rem;
    margin-right: 1rem; 
  }

  .site-header .fa-bars {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    color: #184cb5;
    border: 1px solid #184cb5 ;
    padding: 10px;
    line-height: 50px;
    font-size: 25px;
    position: relative;
    width: 44px;
    height: 35px;
    margin-top: 1rem;
    margin-right: 1rem;
  }
  .site-header .fa-bars:hover{
background-color: #184cb5;
    color: white;
  }
/* Navbar container */
.site-header .navbar {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding: 10px;
  font-size: 0.875em;
  z-index: 99;
}

/* Navbar container when open */
.site-header .navbar.open {
  display: flex;
}

.site-header .navbar ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Navbar list items */
.site-header .navbar ul li {
  margin: 10px 0;
  width: 100%;
  line-height: 30px;
  border-bottom: 1px solid #f0f0f0; /* Add constant border between items */
}

/* Navbar links */
.site-header .navbar ul li a {
  color: #666;
  text-decoration: none;
  margin: 2px 5px;
  box-sizing: border-box;
  text-decoration: none;
  padding: 8px 0; /* Adjust padding to decrease height */
  font-size: 14px; /* Adjust font size to decrease height */
  line-height: 1; /* Ensure consistent height */
}

/* Navbar link hover */
.site-header .navbar ul li a:hover {
  color: #007bff;
  border: none;
}

  .top-header .contact-info {
    display: none;
  }

  .social-and-quote {
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    align-items: center;
  }

  .social-links {
    margin-right: auto; /* Pushes the social links to the left */
    margin-left: auto; /* Pushes the social links to the right */
  }

  .top-header {
    margin-top: 0;
  }
}


@media screen and (max-width: 992px) {
  .top-header {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    padding: 1rem;
    padding-top: 0%;
  }
  .top-header-container {
    padding: 0%;
  }

  .social-and-quote {
    justify-content: center; /* Center social links and quote button on smaller screens */
    margin-top: 0;
  }

  .top-header .contact-info {
    display: none;
  }

  .navigation-links {
    float: left; /* Align navigation links to the left */
    margin-right: 0; /* Remove any right margin */
  }

}