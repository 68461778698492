/* Base styles for the Graphic Designer page */
.Graphic-designer-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  
  .Graphic-designer-container {
    padding: 20px;
    background-color: transparent;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    
  }


  .Graphic-designer-container h2,
  .Flyer-Brochures-Text-container h2,
  .Logo-Design-Text-container h2 {
    color: rgb(80, 75, 75);
    font-size: 20px;
    text-align: start;
    margin: 20px 0;
  }
  

  .Flyer-Brochures-Text-container h2,
  .Logo-Design-Text-container h2 {
    margin-bottom: 30px;
  }
  
 
  .Flyer-Brochures-container {
    display: flex;
    justify-content: center;
    background-color: #efefef;
    width: 100%;
  }
  
 
  .Flyer-Brochures-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
  }
  

  .Flyer-Brochures-Text-container {
    flex: 3;
    padding: 20px;
    text-align: start;
  }
  

  .Flyer-Brochures-Image-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  

  .Flyer-Brochures-Image-container img {
    max-width: 100%;
    height: auto;
    max-height: 200px;
  }
  

  .Logo-Design-container {
    display: flex;
    justify-content: center;
    background-color: transparent;
    width: 1200px;
    margin-top: 40px;
  }
  

  .Logo-Design-Text-container {
    flex: 3;
    padding: 20px;
  }
  

  .Logo-Design-Image-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  

  .Logo-Design-Image-container img {
    max-width: 100%;
    height: auto;
    max-height: 200px;
  }
  

  @media screen and (max-width: 992px) {
    /* Adjust width and padding for smaller screens */
    .Logo-Design-container  {
      width: 100%;
      padding: 8px;
    }
  }
  
  @media screen and (max-width: 768px) {

    .Graphic-designer-container,
    .Flyer-Brochures-container,
    .Logo-Design-container {
      padding: 10px;
    }
  

    .Graphic-designer-container,
    .Flyer-Brochures-container,
    .Logo-Design-container,
    .Flyer-Brochures-content,
    .Logo-Design-content,
    .Flyer-Brochures-Text-container,
    .Logo-Design-Text-container {
      flex-direction: column;
      align-items: center;
    }
  

    .Flyer-Brochures-Image-container,
    .Logo-Design-Image-container {
      width: 100%;
      margin-bottom: 20px;
    }
  

    .Logo-Design-container {
        width: 100%; 
        padding: 8px; 
      }
      .Logo-Design-Image-container {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

  }
  