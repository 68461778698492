/* BlogDetails.css */

.blog-details-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-header {
    position: relative;
    height: 400px;
    overflow: hidden;
  }
  
  .blog-header-image {
    width: 100%;
    height: 100%;
  }
  
  .blog-header-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .blog-title {
    color: var(--secondary-dark);
    letter-spacing: -.05em;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Inter, sans-serif;
    font-size: 70px;
    font-weight: 700;
    line-height: 1.1em;
    font-size: 60px;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .tag {
    background-color: #ccc;
    color: #333;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    font-size: 12px;
  }
  
  .author-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .author-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .blog-content {
    margin-top: 30px;
  }
  
  .blog-content h2 {   
    margin-top: 0;
  }
  
  .comment-section {
    margin-top: 30px;
  }
  
  .comment-section form {
    display: flex;
    flex-direction: column;
  }
  
  .comment-section textarea,
  .comment-section input {
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .comment-section button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  .blog-content {
    font-family: Inter, sans-serif;
}

.blog-content h3 {
    font-size: 36px;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 700;
    color: var(--secondary-dark);
    letter-spacing: -.05em;
    line-height: 1.1em;
}

.blog-content p {
    font-size: 18px;
    line-height: 1.7;
    color: #333;
    font-family: 'Georgia', serif;
    text-align: justify;
    margin-bottom: 1.5em;
}

.blog-content ul,
.blog-content ol {
    margin-bottom: 25px;
}

.blog-content ul li,
.blog-content ol li {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.date-posted {
    margin-top: 20px;
    font-style: italic;
    font-size: 15px;
}


@media (max-width: 768px) {
    .blog-content h3 {
        font-size: 28px;
        margin-top: 20px;
        margin-bottom: 10px;
        line-height: 1.2em;
    }

    .blog-content p {
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 1em;
    }

    .date-posted {
        margin-top: 15px;
        font-size: 14px;
    }
    .blog-title {
        font-size: 24px;
        line-height: 1.2em;
    }
}


@media (max-width: 576px) {
    .blog-title {
        font-size: 24px;
        line-height: 1.3em;
    }
    .blog-content h3 {
        font-size: 24px;
        margin-top: 15px;
        margin-bottom: 8px;
        line-height: 1.1em;
    }

    .blog-content p {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 1em;
    }

    .date-posted {
        margin-top: 10px;
        font-size: 13px;
    }
}
  