.services-container {
  display: flex;
  justify-content: center;
  background-color: #f4f4f4;

}

.Services-Heading-container {
  text-align: center;
  width: 100%; 
  color: rgb(255, 255, 255);
  font-weight: 450;
  font-family: serif;
  font-size: 20px;
  z-index: 1;
  padding: 40px 10px;
  background-attachment: fixed; 
  will-change: transform, opacity;
}
.Services-Heading-container h2{
  font-family: Inter, sans-serif;
  font-size: 30px;
  
}

.services-content-container{
  width: 100%;
  max-width: 100%;

  margin: 0 auto; 
}
.cards-container {
  width: 100%; 
  max-width: 1200px; 
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;


}
.service-card {
  flex: 0 0 calc(33.33% - 20px);
  margin: 10px;
  text-align: center;
  height: auto;

  background-color: #fff;
  border-radius: 20px 0 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  will-change: transform, opacity;
  overflow: hidden;
}


.icon {
  font-size: 36px;
  color: #3498db;
  margin-bottom: 15px;
}

.service-card-content h6 {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: Inter, sans-serif;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}



.service-card-content p {
  font-size: 14px;
margin: 10px;
font-family: Inter, sans-serif;
  color: #101010;
  margin-bottom: 15px;
line-height: 2.5rem;
}

.contact-button {
  color: rgb(255, 255, 255);
  background-color: #003670;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 20px;
}

.image-width {
  max-width: 100%;
  border-radius: 15px 0px 0px 0px;
aspect-ratio: 3/2;
}
@media screen and (max-width: 1200px){
  .cards-container {
    padding-left: 70px;
    padding-right: 70px;
  
  }
  .service-card{
    max-width: 290px;
  }
}

@media screen and (max-width: 992px){
   .cards-container {
    padding-left: 70px;
    padding-right: 70px;

  }

  .service-card {
    flex-basis: calc(50% - 20px);
 
  }
}

@media screen and (max-width: 767px) {
  .service-card {
    flex-basis: calc(100% - 20px);
    max-width: 100%;
  }
  .cards-container {
 padding: 10px;
  }
}
