

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap');

    *{
      font-family: Inter, sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        text-decoration: none;
    }
      html{
          font-size: 62.5%;
      }
      body{
        font-family: Inter, sans-serif;
      max-width: 100%;
      }

        .footer {
            position: relative;
            padding: 0 0 30px 0;
            background: #333;
     
        }
     
        .footer .footer-top {
            background: #023975;
     
        }
        @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1); /* Scale the logo up */
            }
            100% {
              transform: scale(1); /* Scale the logo back to its original size */
            }
          }
          
        .footer-logo{

            width: 200px;
            height: auto;
          
           margin-top: 0px;
            padding: 5px; /* Add padding around the logo */
            animation: pulse 3s ease-in-out infinite;
        }
        .footer .footer-top .footer-info,
        .footer .footer-top .footer-links,
        .footer .footer-top .footer-contact,
        .footer .footer-top .footer-newsletter {
            width: 100%;
          
        }
        
        
        .footer .footer-top h4 {
            font-size: 16px;
            font-weight: 700;
            color: #ffffff;
            text-transform: uppercase;
            position: relative;
            padding-bottom: 12px;
            margin-bottom: 20px;
            letter-spacing: 1px;
        }
        
        .footer .footer-top h4::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 0;
            width: 50px;
            border-bottom: 2px solid #ffffff;
        }
        
        .footer .footer-top .footer-links ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        
        .footer .footer-top .footer-links ul i {
            padding-right: 8px;
            color: #ffffff;
            font-size: 16px;
        }
        
        .footer .footer-top .footer-links ul li {
            border-bottom: 1px solid #ffffff;
            padding: 7px 0;
        }
        
        .footer .footer-top .footer-links ul li:first-child {
            padding-top: 0;
        }
        
        .footer .footer-top .footer-links ul a {
            font-size: 14px;
            color: #ffffff;
        }
        
        .footer .footer-top .footer-links ul a:hover {
            color: #00bfff;
        }
        
        .footer .footer-top .footer-contact p {
            color: #ffffff;
            line-height: 26px;
            padding: 0%;
        }
        
  .SocialLinksHeading {
    margin-bottom: 10px;
  }
  
  .SocialIcons {
    display: flex;
  }
  
  .SocialIcons a {
    display: inline-block;
    margin-right: 10px;
    width: 40px;
    font-size: 2rem;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
  }
  
  .SocialIcons a:hover {
    transform: scale(1.1);
  }
  
  .facebook {
    background-color: #3b5998;
  }
  
  .twitter {
    background-color: #1da1f2; 
  }
  
  .linkedin {
    background-color: #0077b5;
  }
  
  .instagram {
    background-color: #e4405f; 
  }
  .rowFooter{
    max-width: 1200px;

  }
  
  .footer-container {
    background-color: #333;
    padding: 10px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .copyright {
    text-align: left;
    margin-bottom: 10px;
  }

  .Footer-button-container{
    text-align: right;
    font-size: 10px;
  }
  
  .button-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  
  .footer-button{
    background-color: transparent;
    color: white;
    font-size: 1.4rem;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;

  }
  
  .footer-button:hover {
    color: rgb(209, 209, 209);
    text-decoration: underline;
    border-color: rgb(209, 209, 209);
  }
  .contact-in
  {
      width: 80%;
      height: auto;
      margin: auto auto 5rem auto;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;

      background: #fff;
  }

  @media (max-width: 768px) {
    .footer {
        margin-top: 40px;
    
    }
  
    .footer-container .row {
  
      flex-direction: column;
      align-items: center; /* Align items in the center */
    }
        .footer-logo{
            display: none;
        }
    .footer-container .button-list {
        display: flex; 
        justify-content: center; /* Center the buttons horizontally */
        max-width: 100%; /* Set a maximum width */
        margin: 0 auto; /* Center the container horizontally */
      }
    .footer-container .Footer-button-container{
      margin-top: 1rem; /* Decrease the top margin */
    }
  
    .footer-container .button-list button {
      font-size: 1.5rem;
      margin-bottom: 0.5rem; /* Decrease the bottom margin */
    }
    .button {
        padding: 8px 6px; 
      }
  }
  
  @media screen and (max-width: 992px) {
    .rowFooter{
        display: flex;
        flex-direction: row;
     }
    .footer-container .row {
      flex-direction: column;
      align-items: center; /* Align items in the center */
    }
    .footer-container .button-list {
        display: flex; /* Use flexbox */
        justify-content: center; /* Center the buttons horizontally */
        max-width: 100%; /* Set a maximum width */
        margin: 0 auto; /* Center the container horizontally */
      }
  
    .footer-container .Footer-button-container{
      margin-top: 1.8rem;
    }
  
    .footer-container .button-list button {
      font-size: 1.8rem;
      margin-bottom: 0.5rem; /* Decrease the bottom margin */
    }
    .button {
        padding: 8px 6px; 
      }
  }
  
        .footer .footer-top .footer-newsletter input[type="email"] {
            padding: 6px 8px;
            width: 60%;
            border: 1px solid #ffffff;
            background: transparent;
            color: #ffffff;
        }
        
        .NewsLetterSubs{
            border: 0;
            width: 40%;
            padding: 6px 0;
            font-family:Inter, sans-serif;
            text-align: center;
            font-size: 12px;
            color: black;
            background: #ffffff;
            transition: 0.3s;
            cursor: pointer;
        }
        
        .NewsLetterSubs:hover {
            color: #ffffff;
            background: #2356bd;
        }
        
        .footer .footer-top .footer-newsletter p {
            color: #ffffff;
            font-size: 14px;
        }
        
        .footer .credit,
        .footer .copyright {
            text-align: center;
            padding-top: 20px;
        }
        
        @media (min-width: 768px) {
            .footer .credit {
                text-align: right;
            }
            
            .footer .copyright {
                text-align: left;
            }
        }
        .rowFooter{
           display: flex;
           flex-direction: row;
           gap: 20px;

        }
        @media (max-width: 768px) {
            .rowFooter {
                flex-direction: column; /* Stack items vertically on smaller screens */
                align-items: center; /* Center items in the column */
            }
        }
       



