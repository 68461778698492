/* Clients.css */

.clients {
  width: 100%;
  height: auto;
}

.container {
  max-width: 1200px;
  margin: 0 auto; /* Center the container */
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.clients-owl-nav {
  display: flex;
  align-items: center; /* Added for vertical alignment of navigation buttons */
}


.section-header h1 {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #000000;
  line-height: 22px;
  font-family: Inter, sans-serif;
  padding-bottom: 8px;
  border-bottom: 1px solid #036ad9;
  box-sizing: border-box;
  text-align: left;
}

.section-header p {
  font-size: 1.2em;
  color: #555;
}

.clients-carousel-container {
  position: relative;
}

.Clients-owl-prev,
.Clients-owl-next {
  font-size: 1.4em;
  height: 22px;
  width: 22px;
  border: 1px solid #ccc;
  cursor: pointer;
  line-height: 22px;
  display: inline-block;
  zoom: 1;
  border-radius: 2px;
  background-color: #fff;
  margin-left: 4px;
  margin: 2px;
  width: 20px;
  color: #ccc;
}

.Clients-owl-next:hover {
  color: #fff;
  background-color: #0729e6;
}

.Clients-owl-prev:hover {
  color: #fff;
  background-color: #0729e6;
}

.client-item img {
  width: 100%;
  height: 100px; /* Set a fixed height for the images */
  object-fit: contain; /* Maintain aspect ratio and fit within the container */
}
