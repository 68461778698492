.wide-card {
  width: 100%; 
  margin: 0 auto; 
  padding: 60px;
  text-align: center; 
  margin-top: 30px;
border-radius: 0%;
  height: 400px; 
  position: relative; 
}

.wide-card-heading {
  font-size: 30px; 
  font-weight: 700;
  margin-bottom: 30px; 
  font-family: Inter, sans-serif;
  margin-top: 45px;
  color: #ffffff;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.wide-card-button {
  padding: 10px 20px; 
  font-size: 18px; 
  background-color: #2458ac; 
  color: #ffffff; 
  border: solid; 
  border-radius: 4px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.wide-card-button:hover {
  background-color: #1340aa; 
}

.innerContainer {
  width: 100%;
  height: 250px;
  margin: 0 auto; 
  padding: 20px; 
  background-color: #2458ac; 
  border-radius: 25px; 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
}

.wide-card:before {

  position: absolute;
  left: 0;
  width: 100%;
  height: 50px; 
  background-size: cover;
}

.wave {
    position: absolute;
    bottom: -.5rem;
    left: 0;
    width: 100%;
    height: 11rem; 
    background-size: 100rem 11rem; 
    animation: waves 8s linear infinite; 
    background-repeat: repeat-x;
  }
  
  .wave1 {
    z-index: 1; 
    opacity: 0.5; 
    animation-delay: 0s; 
  }
  
  .wave2 {
    z-index: 2; 
    opacity: 0.3; 
    animation-delay: 2s; 
    animation-direction: reverse;
  }
  
  .wave3 {
    z-index: 3; 
    opacity: 0.5; 
    animation-delay: 4s; 
  }
  

  @media screen and (max-width: 597px) {
    .wide-card {
      padding: 30px;
      height: auto;
      border-radius: 15px;
    }
    .wide-card-heading {
      font-size: 24px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .wide-card-button {
      font-size: 16px;
      padding: 8px 16px;
    }
    .innerContainer {
      height: auto;
      padding: 15px;
    }
    .wave-container {
      display: none;
    }
  }
  
  @keyframes waves {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: 100rem; 
    }
  }
