.latest-work-container {
    max-width: 100%;
    padding: 0 30px;
    text-align: center;

  }
  
  .project-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px; /* Adjust the maximum width of the container as needed */
    margin: 0 auto; /* Automatically center the container horizontally */
    padding: 0 20px;
  }
  
  .container-heading h2 {
    color: black;
    margin-top: 10px;
    font-size: 40px;
    font-family: Inter, sans-serif;
    font-weight: 700;
  }
  
  .container-heading p {
    color: rgb(50, 47, 47);
    margin-top: 15px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 20px;
  }
  
  .project-card {
    position: relative;
    width: calc(33.33% - 20px);
    height: auto;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.3s ease; /* Add transition for card animation */
  }
  
  /* Make cards smaller for smaller screens */
  @media screen and (max-width: 768px) {
    .project-card {
      width: 100%;
    }

  }

  @media screen and (max-width: 480px) {
    .project-card {
      width: 100%;
    }
  }
  
  .project-card:hover {
    transform: scale(1.09); /* Scale up the card on hover */
  }
  
  .project-image-container {
    overflow: hidden;
  }
  
  .project-image {
    width: 100%;
    height: auto; 
    object-fit: cover; 
    display: block;
    transition: transform 0.9s ease;
  }
  
  .project-card:hover .project-image {
    transform: scale(1.3); 
  }
  
  .project-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(43, 40, 40, 0.8); 
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    padding: 20px;
  }
  
  .project-card:hover .project-info {
    opacity: 1;
  }
  
  .project-info h3 {
    margin: 0; 
  }
  
  .project-buttons {
    display: flex; /* Ensure buttons are displayed in a row */
    background-color: transparent;
    border: none;
    padding: 10px; /* Add padding to the buttons container */
    cursor: pointer;
 
  }
  .button-left{
    background-color: aliceblue;
  }
  .button-right{
    background-color: aliceblue;
  }
  
  .project-buttons button {
    margin-right: 10px; 
    padding: 8px; 
  }
  
  .project-buttons button:last-child {
    margin-right: 0; /* Remove margin from the last button */
  }
  
  .project-buttons button.Link-button {
    font-size: 25px;
    font-weight: bold;
    color: #0d5db3;
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  
  .project-buttons button.Link-button:hover {
    color: #ffffff;
    background-color: #0d5db3;
  }
  


  .end-button {
    display: block;

    margin: 20px auto; /* Center the button horizontally and provide some spacing */
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 16px;
    background-color: transparent;
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
 
  }
  
  .end-button:hover {
    background-color: #0056b3;
    color: white;
  }
  