/* For larger screens */
.icon-top {
  font-size: 2em;
  color: #ffffff; /* Adjust icon color as needed */
 
}
.icon-container {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically */
  justify-content: flex-start; /* Align items to the start */
}

.main-container {
  text-align: center;
}

.main-heading h3 {
  font-size: 2em; 
  font-weight: normal;
  font-family: Inter, sans-serif;
  margin: 40px;
  font-size: 30px;
}

.main-heading strong {
  font-weight: bold;
  color: #007bff; 
}

.two-sides-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #555;
}

.text-photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-heading {
  font-size: 2.5em;
  font-weight: bold;
  color: #ffffff; /* Adjust heading color as needed */
  margin-bottom: 20px;
}

.text-side {
  flex: 1;
}

.photo-side {
  flex: 1;
  text-align: center;
}

.row {
  display: flex;
  justify-content: space-around;
}

.column {
  flex: 1;
  padding: 20px;
  color: #ffffff;
}

.heading-column {
 display: flex; /* Use flexbox */
 font-family: Inter, sans-serif;
  align-items: center; /* Align items vertically */
  justify-content: flex-start; /* Align items to the start */
  font-size: 22px;
  width: 100%;
  color: #ffffff;
}

.why-us-description {
  padding: 0%;
  text-align: justify; /* Justify the text */
  font-size: 1.4em;
  color: #fff; /* Adjust description color as needed */
}

.photo {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* For smaller screens */
@media (max-width: 768px) {
  
 .main-container{
  display: none;
 }
}
