.portfolio-stats-container {
    display: flex;
    justify-content: space-around;
    
    padding: 40px;

    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: #fff; 
  }
  
  .stat-column {
    flex: 1;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  

  .stat-column .fa-icon {
    color: #345bbf;
    font-size: 35px;
    margin-bottom: 15px; 
  }
  
  .stat-label {
    font-weight: bold;
    margin: 12px 0;
    color: #fff; 
    font-size: 18px;
  }
  
  .stat-value {
    font-size: 24px;
    color: #345bbf;
    font-weight: bold; 
    margin: 0;
  }
  
  .stat-description {
    color: #ddd; 
    margin-top: 8px; 
    font-size: 20px;
  }
  

  @media screen and (max-width: 768px) {
    .portfolio-stats-container {
      display: none;
    }
  }