/* recentProjects.css */

.recent-projects {
    width: 100%;
    height: auto;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.section-header-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    max-width: 1200px;

    border-radius: 5px;
}

.section-header-1 h4 {
    font-size: 32px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #444;
    line-height: 22px;
    font-family: Inter, sans-serif;
    padding-bottom: 8px;
    border-bottom: 1px solid;
    border-bottom-color: #036ad9;
}

.section-header-1 p {
    font-size: 1.5em;
    color: #555;
}

.recent-projects-carousel-container {
    padding: 2px;
    border: 1px solid #eee;
    border-radius: 3px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    height: auto;
}

.project-details {
    margin-top: 10px;
}

.project-details h5 {
    font-size: 16px;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin: 0;
    color: #444;
}

.project-details p {
    padding: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.recent-project-item {
    border: 2px solid #f7f7f7;
    height: auto;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
}

.recent-project-item:hover .project-details h5 {
    color: #036ad9;
}

.recent-project-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.recentProjects-owl-nav {
    margin-top: 10px;
}

.recent-projects-owl-prev,
.recent-projects-owl-next {
    font-size: 1.4em;
    height: 22px;
    width: 22px;
    border: 1px solid #ccc;
    cursor: pointer;
    line-height: 22px;
    display: inline-block;
    border-radius: 2px;
    background-color: #fff;
    margin-left: 4px;
    color: #ccc;
}

.recent-projects-owl-prev:hover,
.recent-projects-owl-next:hover {
    color: #fff;
    background-color: #0729e6;
}
