
.slide-content {
  height: 640px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;

}
.custom-carousel-wrapper{
max-width: 100%;
margin: 0;
padding: 0%;

}
.slide-content .content {
  display: flex;
  justify-content: space-between;
}
.slide-content .text-content {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


/*styling the content in the slides*/

.first-slide-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align the content to the left */
  padding-left: 50px; /* Add some left padding */
}

.first-slide-content .heading-title-1,
.first-slide-content .heading-title-2,
.first-slide-content .slide-description,
.first-slide-content .button-container-wrap {
  text-align: left; 

}

.first-slide-content {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 70%;
  padding: 0 80px;
}

.first-slide-content .heading-title-1 {

  font-size: 8rem;
  letter-spacing: -.05em;
  font-family: Inter, sans-serif;
  font-weight: 700;
}


.heading-title-1 {
  margin-bottom: 8px;
  font-size:5.7rem;

}
.heading-title-2 {
  font-size:5.7rem;
  margin-bottom: 8px;
}
.slide-description{
  font-size: 1.9rem;


}

.slide-content p {
align-items: flex-start;
  margin-bottom: 20px;
}


.slide-content .image-content {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.slide-content .image-content img {
  width: 100%;
  height: 100%; 
  object-fit: cover;
  border-radius: 10px;
}


.slide-content img {
  max-height: 100%;
  max-width: 100%;
}

.slide-content button {
  padding: 12px 48px;
  background-color:#007bff;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 19px;
  margin-top: 40px;
  transition: background-color 0.3s;
}

.slide-content button:hover {
  background-color: #2986e9;
}


.custom-carousel-wrapper {
  position: relative;
  margin-bottom: 30px;
}


.slick-dots {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.slick-dots li {
  margin: 0 3px;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  width: 18px;
  height: 18px;
  padding: 0;
  border: 2px solid transparent; /* Add border */
  background-color: transparent;
  cursor: pointer;
  position: relative;
}

.slick-dots li button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 19px;
  height: 19px;
  background-color: #5a5454;

  transition: background-color 0.3s ease; 
}

.slick-dots li.slick-active button::before {
  background-color: #ffffff; 
}

.arrow-container {
  visibility: hidden;
  opacity: 0;

  transition: opacity 0.2s ease;
}

.custom-carousel-wrapper:hover .arrow-container {
  visibility: visible;
  opacity: 1;
}

.custom-carousel-wrapper:hover .arrow-container {
  visibility: visible;
  opacity: 1;
}

.contentWrap {
  padding: 120px;
}



.image-content img {
  border-radius: 10px;
}

.button-container button {
  font-size: 1.2em;
}

/* Media queries for enhanced responsiveness */
@media screen and (max-width: 1200px) {
  .contentWrap {
    width: 100%;
    padding: 0; 
    box-sizing: border-box; 
  }
  
  .text-content {
    padding: 30px;

  }
  .heading-title-1 {

    font-size:5.4rem;
    margin-bottom: 0px;
  }
  .heading-title-2 {
    font-size:5.4rem;
    margin-bottom: 0px;
  }
  .slide-description{
    font-size: 1.9rem;
  
  }
  .slide-content button {
    padding: 8px 35px;
    font-size: 18px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 992px) {
  .contentWrap {
    width: 100%;
    padding: 20px; 
    box-sizing: border-box; 
  }
  .slide-content{
    height: 450px;

  }
  .text-content{
margin-top: 20px;
   padding: 20px;
  }
  .heading-title-1 {

    font-size:4.6rem;
    margin-bottom: 0px;
  }
  .heading-title-2 {
    font-size:4.8rem;
    margin-bottom: 0px;
  }
  .slide-description{
    font-size: 1.8rem;

  }
  .slide-content button {
    padding: 8px 35px;
    font-size: 12px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .contentWrap {
    width: 100%; /* Take full width */
    padding: 0; /* Remove padding */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  .slide-content{
    height: 350px;

  }
  .first-slide-content {
    display: flex;
    flex-direction: column;
    padding: 0 20px; /* Reduce padding on smaller screens */
  }
  .first-slide-content .heading-title-1 {
    font-size: 2.5rem;
    font-weight: 700;
  }
 
  .text-content{

   padding: 20px;
  }
  .custom-carousel-wrapper:hover .arrow-container {
    visibility: hidden;
  }
  .custom-carousel-wrapper{
    margin-bottom: 120px;
  }
  .button-container-wrap{
    visibility: hidden;
  }
  .heading-title-1 {

    font-size:3.6rem;
    margin-bottom: 0px;
  }
  .heading-title-2 {
    font-size:3.5rem;
    margin-bottom: 0px;
  }
  .slide-description{
    font-size: 1.6rem;
  
  }
}



@media screen and (max-width: 576px) {
  .contentWrap {
    width: 100%; /* Take full width */
    
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }

  .custom-carousel-wrapper:hover .arrow-container {
    visibility: hidden;
  }
  .slide-content{
    height: 275px;

  }
  .first-slide-content .heading-title-1 {
    font-size: 2rem;
    font-weight: 700;
  }
  .text-content{
    margin-top: 30px;
  padding: 15px;
  }
  .custom-carousel-wrapper{
    margin-bottom: 120px;
  }
  .button-container-wrap{
    visibility: hidden;
  }
  .heading-title-1 {

    font-size:2rem;
    margin-bottom: 0px;
  }
  .heading-title-2 {
    font-size:2rem;
    margin-bottom: 0px;
  }
  .slide-description{
    font-size: 1.4rem;
    text-align: left;
  }
  .first-slide-content {
    padding: 0 10px; /* Further reduce padding on smaller screens */
  }
}

