.contact-heading {
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: black;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.contact-in {
  width: 80%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}


.contact-map {
  order: -1; 
  height: 450px;
  margin-bottom: 40px;
}

.contact-map iframe {
  width: 100%;
  height: 100%;
}
.contact-form h2 {
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-weight: 500;
  margin-left: 2rem;
  text-align: left;
  margin-bottom: 20px; 
  padding-bottom: 10px; 
  background-image: linear-gradient(to right, #003785, #ffffff); /* Define linear gradient */
  background-position: bottom; 
  background-size: 100% 1px; /* Set the size of the gradient to cover the entire width and have a height of 2px */
  background-repeat: no-repeat; /* Prevent the gradient from repeating */
}
.contact-form {
  width: calc(50% - 10px); /* Adjust width as needed */
}
.contact-info-container {
  width: calc(50% - 10px); /* Adjust width as needed */
  margin-left: 20px; /* Adjust margin as needed */
}

.contact-form input,
.contact-form textarea {
  margin-left: 2rem;
  width: 95%;
  height: 40px;
  color: #000;
  border: 1px solid #dfdfdf;

  outline: none;
  margin-bottom: 20px;
  padding: 15px;
  font-size: 1.5rem;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #aaa;
  font-size: 1.5rem;
}
.contact-form-phone{
  border-radius: 0px;
}
.contact-form-btn {
  align-items: flex-start;
  background-color: #2356bd;
  color: #ffffff;
  border: none;
  font-family: Inter, sans-serif;
  font-size: 16px;
  padding: 0.3rem 1rem;
  line-height: 33px!important;
  cursor: pointer;
  width: 150px;
  border-radius: 4px;
  margin-left: 2rem;
  transition: all 0.3s ease; /* Adding smooth transition */
}

.contact-form-btn:hover {
  letter-spacing: 0.1rem;
  opacity: 0.9;
  color: white;
  background: #033282;
}


@media screen and (max-width: 768px) {

  .contact-form input,
  .contact-form textarea {
    margin-left: 0; 
    width: calc(100% - 30px);
  }
  .contact-in {
    flex-direction: column;
    box-shadow: none;
    padding: 0%;
    width: 100%;
  }
  .contact-map,
  .contact-form {
    width: 100%;
  }

  .contact-form,
  .contact-info-container {
    width: 100%; /* Ensure full width on smaller screens */
    margin-left: 0; /* Remove margin on smaller screens */
    margin-bottom: 20px; /* Add margin bottom for spacing */
  }
  .contact-form input,
  .contact-form textarea
  {

    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 597px)  {
  .contact-form input,
  .contact-form textarea {
    margin-left: 10px; /* Remove left margin on smaller screens */
    width: calc(100% - 20px); /* Adjust width */
    max-width: none; /* Remove maximum width */
  }
}
@media screen and (max-width: 992px)  {
  .contact-form input,
  .contact-form textarea {
    margin-left: 10px; /* Remove left margin on smaller screens */
    width: calc(100% - 20px); /* Adjust width */
    max-width: none; /* Remove maximum width */
  }
}
