.categories-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  
  .categories-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  
  .categories-card {
    color: #000;
    background-color: #fff;
    border: 1px solid #eee;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding: 20px;
    margin: 20px;
    border-radius: 15px 0px 0px 0px;
    min-width: 300px;
    transition: all ease .3s;
    flex: 0 0 calc(30.33% - 20px);
  }
  
  .card-content p {
    color: #413b3b;
    font-family: sans-serif;
    word-spacing: 5px;
    line-height: 1.7;
  }
  
  .card-image {
    max-width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    padding: 10px;
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; 
  }
  
  h6 {
    font-size: 18px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  p {
    font-size: 14px;
    color: #101010;
    margin-bottom: 15px;
  }
  
  .button-wrapper {
    margin-top: 20px;
  }
  
  .get-free-quote-button {
    background: transparent;
    color: #007bff;
    border: 2px solid #007bff;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  
  .get-free-quote-button:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  
  
  
  @media screen and (max-width: 1200px){
    .categories-container {
  padding: 0%;
    }
    .categories-card{
      flex: 0 0 calc(30.33% - 20px) !important;
    }
  }
  
  @media screen and (max-width: 992px){
  
    .categories-container {
  padding: 0%;
  
    }
    .categories-card{
  min-width: 300px;
   
    }
  }
  
  @media screen and (max-width: 767px) {
    .categories-card {
      flex-basis: calc(100% - 20px) !important;
    }
    .categories-container{
   padding: 10px;
    }
  }
  